import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import './ActivityModal.css';

export const timeOptions = [
    {value: 'less_6m', label: '<6m'},
    {value: '6m_1y', label: '6m - 1y'},
    {value: '1y_3y', label: '1y - 3y'},
    {value: '3y_5y', label: '3y - 5y'},
    {value: 'more_5y', label: '> 5y'},
];

const generateTimeOptions = (startHour = 0, startMinute = 0, endHour = 24, interval = 15) => {
    const times = [];
    for (let hour = startHour; hour < endHour; hour++) {
        for (let minute = startMinute; minute < 60; minute += interval) {
            const time = new Date();
            time.setHours(hour, minute, 0, 0);
            const label = time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
            times.push({value: label, label});
        }
        startMinute = 0; // Reset minutes to 0 after the first hour
    }
    return times;
};

const generateEndTimeOptions = (startHour = 0, startMinute = 0, endHour = 24, interval = 15) => {
    const times = [];
    const time = new Date();
    time.setHours(startHour, startMinute, 0, 0);
    for (let i = 0; i < 16; i++) {
        time.setMinutes(time.getMinutes() + 15);
        const label = time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
        times.push({value: label, label});
    }
    return times;
};

const getNextIntervalTime = (interval = 15) => {
    const now = new Date();
    const roundedMinutes = Math.ceil(now.getMinutes() / interval) * interval;
    now.setMinutes(roundedMinutes);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        minWidth: '120px',
        border: '1px solid #ced4da', // Light grey border
        borderRadius: '4px', // Rounded corners
        boxShadow: 'none', // Remove box shadow
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        maxHeight: '150px', // Limit the height of the menu
        overflowY: 'auto', // Enable scroll
    }),
    option: (provided) => ({
        ...provided,
        textAlign: 'left',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

const ActivityModal = ({isOpen, onRequestClose, addActivity, clickPosition, editActivity}) => {
    const [activityOptions, setActivityOptions] = useState([]);
    const [activity, setActivity] = useState(editActivity ? editActivity.id : null);
    const [startTime, setStartTime] = useState(editActivity ? editActivity.startTime : '');
    const [endTime, setEndTime] = useState(editActivity ? editActivity.endTime : '');
    const [howLong, setHowLong] = useState(editActivity ? editActivity.howLong : '');
    const [startTimeOptions, setStartTimeOptions] = useState([]);
    const [endTimeOptions, setEndTimeOptions] = useState([]);
    const [isActivityValid, setIsActivityValid] = useState(true);
    const [isHowLongValid, setIsHowLongValid] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
    });

    useEffect(() => {
        setStartTimeOptions(generateTimeOptions());
    }, []);

    useEffect(() => {
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const newEndTimeOptions = generateEndTimeOptions(startHour, startMinute, startHour + 4);
        setEndTimeOptions(newEndTimeOptions);
        const nextEndTime = newEndTimeOptions[3] ? newEndTimeOptions[3].value : '';
        setEndTime(nextEndTime);
    }, [startTime]);

    useEffect(() => {
        if (isOpen) {
            let top = clickPosition.y;
            let left = clickPosition.x;

            const modalWidth = 400;
            const modalHeight = 300; // estimated height
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            if (left + modalWidth > screenWidth) {
                left = screenWidth - modalWidth - 10; // 10px margin from the edge
            }

            if (top + modalHeight > screenHeight) {
                top = screenHeight - modalHeight - 10; // 10px margin from the edge
            }

            setModalStyle((prevStyle) => ({
                ...prevStyle,
                content: {
                    ...prevStyle.content,
                    top: `${top}px`,
                    left: `${left}px`,
                    transform: 'none',
                },
            }));

            if (!editActivity) {
                const nextIntervalTime = getNextIntervalTime();
                setStartTime(nextIntervalTime);
                const [startHour, startMinute] = nextIntervalTime.split(':').map(Number);
                const newEndTimeOptions = generateEndTimeOptions(startHour, startMinute + 15, startHour + 4);
                setEndTimeOptions(newEndTimeOptions);
                const nextEndTime = newEndTimeOptions[2] ? newEndTimeOptions[2].value : '';
                setEndTime(nextEndTime);
            } else {
                const [startHour, startMinute] = editActivity.startTime.split(':').map(Number);
                const newEndTimeOptions = generateEndTimeOptions(startHour, startMinute, startHour + 8);
                setEndTimeOptions(newEndTimeOptions);
                if (editActivity) {
                    setEndTime(editActivity.endTime);
                    setHowLong(editActivity.howLong);
                    setActivity(editActivity);
                }
            }

        }
    }, [isOpen, clickPosition, editActivity]);

    useEffect(() => {
        const fetchActivityOptions = async () => {
            try {
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_API + '/fitness/v1/activity_types?per_page=200');
                const data = await response.json();

                const options = data.map((activity) => ({
                    value: activity.id,
                    label: activity.name
                }));

                setActivityOptions(options);
            } catch (error) {
                setError('Failed to load activity types');
            } finally {
                setLoading(false);
            }
        };

        fetchActivityOptions();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const closeModal = () => {
        setActivity(null);
        // setStartTime('');
        // setEndTime('');
        setHowLong('');
        onRequestClose();
    };

    const handleTimeChange = (selectedOption) => {
        setHowLong(selectedOption.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!activity) {
            setIsActivityValid(false);
        } else {
            setIsActivityValid(true);
        }

        if (!howLong) {
            setIsHowLongValid(false);
        } else {
            setIsHowLongValid(true);
        }

        if (howLong) {
            // If valid, call the parent onSave function with activity data
            addActivity({id: activity.id, name: activity.name, startTime, endTime, howLong});
            // Reset form fields after successful save
            setActivity(null);
            // setStartTime(getNextIntervalTime());
            // setEndTime('');
            setHowLong(null);
            setIsActivityValid(true);
            setIsHowLongValid(true);
            closeModal();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={modalStyle}
            shouldCloseOnOverlayClick={true}
        >
            <h2 className="modal-title">{editActivity ? 'Edit Activity' : 'Add Activity'}</h2>
            <form onSubmit={handleSubmit} className={"activity-model-body"}>
                <div className="form-group time-group">
                    <div className="time-select">
                        <label className="form-label">From:</label>
                        <Select
                            options={startTimeOptions}
                            onChange={(selectedOption) => setStartTime(selectedOption.value)}
                            placeholder="Select start time"
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            value={startTime ? {value: startTime, label: startTime} : null}
                        />
                    </div>
                    <div className="time-select">
                        <label className="form-label">To:</label>
                        <Select
                            options={endTimeOptions}
                            onChange={(selectedOption) => setEndTime(selectedOption.value)}
                            placeholder="Select end time"
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            value={endTime ? {value: endTime, label: endTime} : null}
                            isDisabled={!startTime} // Disable end time select until start time is selected
                        />
                    </div>
                </div>
                <div className="form-group">
                    <Select
                        options={activityOptions}
                        onChange={(selectedOption) => {
                            setActivity({id: selectedOption.value, name: selectedOption.label});
                            setIsActivityValid(true);
                        }}
                        placeholder="Search and select activity..."
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        value={activity ? {
                            value: activity.id,
                            label: activity.name,
                        } : null}
                        className={isActivityValid ? '' : 'invalid'}
                    />
                    {!isActivityValid && <div className="error-message">Activity is required</div>}
                </div>
                <div className="form-group">
                    <Select
                        options={timeOptions}
                        onChange={handleTimeChange}
                        placeholder="How Long"
                        value={activity ? timeOptions.find(p => p.value === howLong) : null}
                        className={isHowLongValid ? '' : 'invalid'}
                    />
                    {!isHowLongValid && <div className="error-message">How Long is required</div>}
                </div>
                <div className="modal-buttons">
                    <button type="submit" className="submit-button">{editActivity ? 'Save' : 'Add'}</button>
                    <button className="close-button" onClick={closeModal}>Close</button>
                </div>
            </form>
        </Modal>
    );
};

export default ActivityModal;

// CustomEvent.js
import React from 'react';
import './CustomEvent.css'; // Import custom CSS for the event

const CustomEvent = ({event}) => {
    return (
        <div className="custom-event">
            <strong>{event.resource.workout.name}</strong>
            <p>Code: {event.resource.workout.code} [N{event.resource.workout.pl}]</p>
            <p>{Math.floor(event.resource.workout.duration)} minutes [{event.resource.workout.calories} calories]</p>
            <p>Type: {event.resource.workout.groupType} - {event.resource.workout.workoutType}</p>
            <p>{event.resource.workout.isFB ? " [FB] " : event.resource.workout.isLB ? "" +
                "[LB] " : event.resource.workout.isUP ? " [UP] " : ""}{event.resource.workout.isCardio ? " [Cardio] " : ""}
                {event.resource.workout.isCore ? " [Core] " : ""}{event.resource.workout.isLeg ? " [Leg] " : ""}
                {event.resource.workout.isPull ? " [Pull] " : ""}{event.resource.workout.isPush ? " [Push] " : ""}</p>
        </div>
    );
};

export default CustomEvent;

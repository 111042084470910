import React, {useState} from 'react';
import axios from 'axios';
import './App.css';
import './Workout.css';
import './FitnessForm.css';
import CalendarView from "./CalendarView"; // Ensure this import is included

const FitnessForm = ({plData}) => {
    const [trainingPreferences, setTrainingPreferences] = useState([]);
    const [weekdays, setWeekdays] = useState([]);
    const [workoutsTime, setWorkoutsTime] = useState('LESS_30');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');

    const handlePreferenceChange = (event) => {
        const value = event.target.value;
        console.log(value)
        setTrainingPreferences(
            trainingPreferences.includes(value)
                ? trainingPreferences.filter((item) => item !== value)
                : [...trainingPreferences, value]
        );
    };

    console.log(trainingPreferences)

    const handleWeekdayChange = (event) => {
        const value = event.target.value;
        setWeekdays(
            weekdays.includes(value)
                ? weekdays.filter((item) => item !== value)
                : [...weekdays, value]
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (trainingPreferences.length === 0) {
            setError('Please select at least one training preference.');
            return;
        }

        if (weekdays.length === 0) {
            setError('Please select at least one weekday.');
            return;
        }

        setError('');

        const requestData = {
            trainingPreference: trainingPreferences,
            trainingDays: weekdays,
            workoutDuration: workoutsTime,
            xTraining: plData[0],
            cardio: plData[1],
            stretch: plData[2],
            mobility: plData[3],
            core: plData[4],
            strength: plData[5]
        };

        try {
            const result = await axios.post(process.env.REACT_APP_API + '/fitness/v1/ai/plan/test', requestData);
            setResponse(result.data);
        } catch (error) {
            console.error('Error making request:', error);
        }
    };

    return (
        <div className="form-body">
            <form onSubmit={handleSubmit}>
                <div className={"element"}>
                    <label>Training Preferences</label>
                    <div className={"checkbox-list"}>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="CARDIO" onChange={handlePreferenceChange}/> Cardio
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="STRENGTH" onChange={handlePreferenceChange}/> Strength
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="MOBILITY" onChange={handlePreferenceChange}/> Mobility
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="CORE" onChange={handlePreferenceChange}/> Core
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="STRETCH" onChange={handlePreferenceChange}/> Stretch
                        </label>
                    </div>
                </div>

                <div className={"element"}>
                    <label>Workouts Time</label>
                    <select value={workoutsTime} onChange={(e) => setWorkoutsTime(e.target.value)}>
                        <option value="LESS_30">LESS 30</option>
                        <option value="FROM_30_TO_45">30 TO 45</option>
                        <option value="OVER_45">OVER 45</option>
                    </select>
                </div>

                <div className={"element"}>
                    <label>Weekdays</label>
                    <div className={"checkbox-list"}>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="MONDAY" onChange={handleWeekdayChange}/> Monday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="TUESDAY" onChange={handleWeekdayChange}/> Tuesday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="WEDNESDAY" onChange={handleWeekdayChange}/> Wednesday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="THURSDAY" onChange={handleWeekdayChange}/> Thursday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="FRIDAY" onChange={handleWeekdayChange}/> Friday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="SATURDAY" onChange={handleWeekdayChange}/> Saturday
                        </label>
                        <label className={"checkbox"}>
                            <input type="checkbox" value="SUNDAY" onChange={handleWeekdayChange}/> Sunday
                        </label>
                    </div>
                </div>

                <div className={"button-div"}>
                    {error && <p className="error">{error}</p>}
                    <button className={"submit-button"} type="submit">Generate</button>
                </div>
            </form>

            {response && (
                <div>
                    <CalendarView workoutDays={response}/>
                </div>
            )}
        </div>
    );
};

export default FitnessForm;

import React from 'react';
import './DayColumn.css';
import {timeOptions} from "./ActivityModal";

const DayColumn = ({ day, activities, onAddClick, onActivityClick }) => {
    return (
        <div className="day-column">
            <h2>{day}</h2>
            {activities.map((activity, index) => (
                <div
                    key={index}
                    className="activity"
                    onClick={(e) => onActivityClick(day, activity, e)}
                >
                    {activity.name}
                    [{timeOptions.find(t => t.value === activity.howLong).label}]
                    ({activity.startTime} - {activity.endTime})
                </div>
            ))}
            <button className="add-button" onClick={(e) => onAddClick(day, e)}>+</button>
        </div>
    );
};

export default DayColumn;

import React, {useEffect, useState} from 'react';
import ActivityModal from './ActivityModal';
import DayColumn from './DayColumn';
import './App.css';
import FitnessForm from "./FitnessForm";
import {Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip} from "chart.js";
import {Radar} from "react-chartjs-2";
import axios from "axios";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const initialData = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
};

const options = {
    scale: {
        ticks: {
            beginAtZero: true,
            stepSize: 1
        },
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
};

const App = () => {
    const [plData, setPlData] = useState();
    const [data, setData] = useState(initialData);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    const [editActivity, setEditActivity] = useState(null);
    const [clickPosition, setClickPosition] = useState({x: 0, y: 0});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.post(process.env.REACT_APP_API + '/fitness/v1/level', data);
                setPlData([result.data.x_training, result.data.cardio, result.data.stretch, result.data.mobility, result.data.core, result.data.strength]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
        // No cleanup function needed here
        return () => {
        };

    }, [data]);

    const openModal = (day, activity = null, position = {x: 0, y: 0}) => {
        setSelectedDay(day);
        setEditActivity(activity);
        setClickPosition(position);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setEditActivity(null);
    };

    const addActivity = (activity) => {
        setData((prevData) => {
            const updatedDay = editActivity
                ? prevData[selectedDay].map((act) => (act === editActivity ? activity : act))
                : [...prevData[selectedDay], activity];
            return {...prevData, [selectedDay]: updatedDay};
        });
        closeModal();
    };

    const handleActivityClick = (day, activity, event) => {
        const position = {x: event.clientX, y: event.clientY};
        openModal(day, activity, position);
    };

    const chartData = {
        labels: [
            'X Training',
            'Cardio',
            'Stretch',
            'Mobility',
            'Core',
            'Strength'
        ],
        datasets: [
            {
                data: plData,
                backgroundColor: 'rgba(34, 202, 236, 0.2)',
                borderColor: 'rgba(34, 202, 236, 1)',
                borderWidth: 1
            },
            // {
            //     data: [plData[0],plData[1],plData[2],2,3,plData[5],plData[6]],
            //     // data: plData,
            //     backgroundColor: 'rgba(255, 252, 127, 0.2)',
            //     borderColor: 'rgba(34, 25, 54, 1)',
            //     borderWidth: 1
            // }
        ]
    };

    return (
        <div className={"bar-container"}>
            <div className={"radar-chart"}>
                <Radar data={chartData} options={options}/>
            </div>
            <div className={"right-bar"}>
                <div className="container">
                    {Object.keys(data).map((day) => (
                        <DayColumn
                            key={day}
                            day={day}
                            activities={data[day]}
                            onAddClick={(day, e) => openModal(day, null, {x: e.clientX, y: e.clientY})}
                            onActivityClick={handleActivityClick}
                        />
                    ))}
                    <ActivityModal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        addActivity={addActivity}
                        clickPosition={clickPosition}
                        editActivity={editActivity}
                    />
                </div>
                <div className={"options-bar"}>
                    <div className="form-container">
                        <FitnessForm plData={plData}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default App;
